import React from 'react'

type PropsType = {}

const InWorkIcon: React.FC<PropsType> = (props) => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833313 8C0.833313 3.58172 4.41504 0 8.83331 0H28.8333C33.2516 0 36.8333 3.58172 36.8333 8V28C36.8333 32.4183 33.2516 36 28.8333 36H8.83331C4.41503 36 0.833313 32.4183 0.833313 28V8Z"
        fill="#FBB13C"
        fillOpacity="0.16"
      />
      <path
        d="M18.8333 12C18.702 12 18.5719 12.0258 18.4506 12.0761C18.3293 12.1263 18.219 12.2 18.1261 12.2928C18.0333 12.3857 17.9596 12.4959 17.9094 12.6173C17.8591 12.7386 17.8333 12.8687 17.8333 13V17.3838L15.4027 18.6069C15.2854 18.6659 15.1809 18.7474 15.0951 18.8468C15.0093 18.9462 14.9439 19.0615 14.9027 19.1861C14.8615 19.3108 14.8453 19.4423 14.8549 19.5733C14.8645 19.7042 14.8998 19.8319 14.9588 19.9492C15.0178 20.0665 15.0993 20.171 15.1987 20.2568C15.2981 20.3426 15.4134 20.4079 15.538 20.4491C15.6627 20.4904 15.7942 20.5066 15.9251 20.497C16.0561 20.4874 16.1838 20.4521 16.3011 20.3931L19.2825 18.8931C19.4482 18.81 19.5874 18.6824 19.6847 18.5247C19.7819 18.367 19.8334 18.1853 19.8333 18V13C19.8334 12.8687 19.8075 12.7386 19.7573 12.6173C19.707 12.4959 19.6334 12.3857 19.5405 12.2928C19.4476 12.2 19.3374 12.1263 19.216 12.0761C19.0947 12.0258 18.9646 12 18.8333 12ZM18.8333 8C16.8555 8 14.9221 8.58649 13.2776 9.6853C11.6331 10.7841 10.3514 12.3459 9.59452 14.1732C8.83765 16.0004 8.63961 18.0111 9.02546 19.9509C9.41132 21.8907 10.3637 23.6725 11.7622 25.0711C13.1608 26.4696 14.9426 27.422 16.8824 27.8079C18.8222 28.1937 20.8329 27.9957 22.6602 27.2388C24.4874 26.4819 26.0492 25.2002 27.148 23.5557C28.2468 21.9112 28.8333 19.9778 28.8333 18C28.8303 15.3488 27.7757 12.807 25.901 10.9323C24.0263 9.05758 21.4846 8.00303 18.8333 8ZM18.8333 26C17.2511 26 15.7043 25.5308 14.3888 24.6518C13.0732 23.7727 12.0478 22.5233 11.4423 21.0615C10.8368 19.5997 10.6784 17.9911 10.987 16.4393C11.2957 14.8874 12.0576 13.462 13.1765 12.3431C14.2953 11.2243 15.7207 10.4624 17.2726 10.1537C18.8244 9.84504 20.433 10.0035 21.8948 10.609C23.3566 11.2145 24.606 12.2398 25.4851 13.5554C26.3641 14.871 26.8333 16.4177 26.8333 18C26.8309 20.121 25.9873 22.1544 24.4875 23.6542C22.9877 25.1539 20.9543 25.9976 18.8333 26Z"
        fill="#FBB13C"
      />
    </svg>
  )
}

export default InWorkIcon
