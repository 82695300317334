export const colors = {
  icons: {
    color: 'rgba(255, 255, 255, 0.72)',
  },

  buttons: {
    color: '#36A1EA',
    colorSecondary: '#F5F7FB',
    text: '#FFFFFF',
  },

  text: {
    color: '#40434E',
  },

  background: {
    color: '#fff',
    colorSecondary: '#F5F7FB',
  },

  tags: {
    positive: '#1DC376',
    negative: '#FF5154',
    basic: 'rgba(64, 67, 78, 0.56)',
  },
}

export type colorsType = typeof colors
