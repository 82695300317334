import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import FlexCenter from 'Components/common/FlexContainers/FlexCenter'
import Button from 'Components/common/Button'
import ArrowButtonIcon from 'Assets/icons/ArrowButtonIcon'

type PropsType = {
  margin?: string
}

const SprintSelect: React.FC<PropsType> = (props) => {
  const [countSelect, setCountSelect] = useState('Спринт №5')

  function countSelectHandler(e: ChangeEvent<HTMLSelectElement>) {
    setCountSelect(e.target.value)
  }
  return (
    <FlexCenter margin={props.margin} position="relative">
      <StyledMockSelect>
        <FlexCenter>
          <Button size="S" variant="full" icon={<ArrowButtonIcon />} />
          <Button size="S" variant="clear" text={countSelect} margin="0 0 0 8px" />
        </FlexCenter>
      </StyledMockSelect>
      <StyledSelect onChange={(e) => countSelectHandler(e)}>
        <option>Спринт №5</option>
        <option>Спринт №4</option>
        <option>Спринт №3</option>
        <option>Спринт №2</option>
        <option>Спринт №1</option>
      </StyledSelect>
    </FlexCenter>
  )
}

export default SprintSelect

const StyledMockSelect = styled.div<PropsType>`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 6px;
`
const StyledSelect = styled.select<PropsType>`
  display: block;
  padding-left: 32px;
  height: 24px;
  border-width: 0;
  border-radius: 6px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
`
