import React from 'react'
import styled from 'styled-components'

type PropsType = {
  padding?: string
  height?: string
  minWidth?: string
  children?: React.ReactNode
}

const Container: React.FC<PropsType> = (props) => {
  return <StyledContainer {...props}>{props.children}</StyledContainer>
}

export default Container

const StyledContainer = styled.div<PropsType>`
  position: relative;
  padding: ${(props) => props.padding || '32px 36px 0 36px'};
  height: ${(props) => props.height};
  min-width: ${(props) => props.minWidth};
`
