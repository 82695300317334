import React, { useState } from 'react'
import styled from 'styled-components'
import SearchIcon from 'Assets/icons/SearchIcon'
import FlexBetween from 'Components/common/FlexContainers/FlexBetween'

type PropsType = {}

const SearchInput: React.FC<PropsType> = (props) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <StyledSearchWrapper {...props}>
      <FlexBetween height="100%">
        <StyledInput
          placeholder="Поиск сотрудника"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          type="text"
          name="search"
        />

        <StyledIconWrapper>
          <SearchIcon />
        </StyledIconWrapper>
      </FlexBetween>
    </StyledSearchWrapper>
  )
}

export default SearchInput

const StyledSearchWrapper = styled.div<PropsType>`
  position: relative;
  height: 36px;
  border-radius: 5px;
  background-color: #ffffff;

  & path {
    opacity: 0.56;
  }
  &:focus-within {
    box-shadow: 0 0 2px 2px #36a1ea;
    & path {
      opacity: 1;
    }
  }
`

const StyledInput = styled.input`
  padding: 0 10px;
  border-radius: 5px;
  border-width: 0;
  width: 90%;
  height: 100%;
  outline: none;
`

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 11px;
`
