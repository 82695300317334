import React from 'react'

type PropsType = {}

const SearchIcon: React.FC<PropsType> = (props) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.763 7.279C9.226 6.546 9.494 5.678 9.494 4.747C9.494 2.125 7.369 0 4.747 0C2.125 0 0 2.125 0 4.747C0 7.369 2.125 9.494 4.747 9.494C5.668 9.494 6.527 9.232 7.254 8.779L10.533 12.016L12.016 10.533C12.016 10.533 12.016 10.533 8.763 7.279ZM4.747 7.384C3.291 7.384 2.11 6.203 2.11 4.747C2.11 3.291 3.291 2.11 4.747 2.11C6.204 2.11 7.385 3.291 7.385 4.747C7.385 6.203 6.203 7.384 4.747 7.384Z"
        fill="#40434E"
      />
    </svg>
  )
}

export default SearchIcon
