import React from 'react'
import Flex, { FlexPropsType } from './Flex'

const FlexCenter: React.FC<FlexPropsType> = (props) => {
  return (
    <Flex justify="center" align="center" {...props}>
      {props.children}
    </Flex>
  )
}

export default FlexCenter
