import React from 'react'
import styled from 'styled-components'
import FlexCenter from 'Components/common/FlexContainers/Flex'
import SprintSelect from './items/SprintSelect'
import FlexBetween from 'Components/common/FlexContainers/FlexBetween'
import Button from 'Components/common/Button'
import AllTasksIcon from 'Assets/icons/AllTasksIcon'
import ElementPanel from './items/ElementPanel'
import InWorkIcon from 'Assets/icons/InWorkIcon'
import { ReactComponent as Graph } from 'Assets/img/graph.svg'
import TasksSort from 'Components/main/taskPanel/items/TasksSort'
import CompletedIcon from 'Assets/icons/CompletedIcon'
import FlexColumn from 'Components/common/FlexContainers/FlexColumn'
import TasksList from 'Components/main/taskPanel/items/TasksList'
import { StyledBottomSmoke } from 'Components/main/employeesPanel/EmployeesPanel'
import RollUpArrowIcon from 'Assets/icons/RollUpArrowIcon'

type PropsType = {}

const taskPanelItems = [
  { id: 1, icon: <AllTasksIcon />, text: 'Всего задач', countTasks: 352, percent: -2.1 },
  { id: 2, icon: <InWorkIcon />, text: 'В работе', countTasks: 303, percent: 12.9 },
  { id: 3, icon: <CompletedIcon />, text: 'Завершено', countTasks: 1500, percent: 0 },
]

const TaskPanel: React.FC<PropsType> = (props) => {
  return (
    <StyledTaskPanelWrapper>
      <FlexColumn height="100%" padding="32px 36px 0 36px">
        <FlexBetween width="100%">
          <FlexCenter margin="7px 0">
            <StyledTaskPanelTitle>Задачи</StyledTaskPanelTitle>

            <SprintSelect margin="0 0 0 13px" />
          </FlexCenter>
          <Button size="L" variant="base" text="Добавить задачу" />
        </FlexBetween>

        <FlexBetween margin="17px 0 0 0">
          <FlexBetween>
            {taskPanelItems.map(({ id, icon, text, countTasks, percent }) => (
              <ElementPanel
                key={id}
                icon={icon}
                text={text}
                countTasks={countTasks}
                percent={percent}
              />
            ))}
          </FlexBetween>
          <StyledGraphWrapper>
            <Graph />
          </StyledGraphWrapper>
        </FlexBetween>

        <TasksSort />
        <TasksList />
        <StyledBottomSmoke />
      </FlexColumn>

      <StyledRollUpButtonWrapper>
        <Button size="L" variant="full" icon={<RollUpArrowIcon />} />
      </StyledRollUpButtonWrapper>
    </StyledTaskPanelWrapper>
  )
}

export default TaskPanel

const StyledTaskPanelWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  min-width: 937px;
`

const StyledTaskPanelTitle = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 700;
`

const StyledRollUpButtonWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: -24px;
`

const StyledGraphWrapper = styled.div`
  transform: translateY(-10px);
`
