import React from 'react'
import styled from 'styled-components'

type PropsType = {
  margin?: string
  children?: React.ReactNode
}

const Li: React.FC<PropsType> = (props) => {
  return <StyledLi {...props}>{props.children}</StyledLi>
}

export default Li

const StyledLi = styled.li<PropsType>`
  margin: ${(props) => props.margin};
`
