import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'Style/colors'
import Tag from 'Components/common/Tag'

type PropsType = {
  icon: JSX.Element
  text: string
  countTasks: number
  percent: number
}

const ElementPanel: React.FC<PropsType> = (props) => {
  let percentText = ''
  let variant: 'negative' | 'positive' | 'basic' | '' = ''
  if (props.percent > 0) {
    variant = 'positive'
    percentText = `+ ${props.percent}`
  } else if (props.percent < 0) {
    variant = 'negative'
    percentText = `- ${props.percent * -1}`
  } else if (props.percent === 0) {
    variant = 'basic'
    percentText = `+ ${props.percent}`
  }

  return (
    <StyledElementPanelWrapper background={colors.background.colorSecondary}>
      <StyledElementIconWrapper>{props.icon}</StyledElementIconWrapper>

      <StyledElementTitle>{props.text}</StyledElementTitle>
      <StyledElementCountTasks>{props.countTasks}</StyledElementCountTasks>
      <Tag margin="14px 0 0 0" variant={variant}>
        {percentText} %
      </Tag>
    </StyledElementPanelWrapper>
  )
}

export default ElementPanel

type StyledElementPanelWrapperType = {
  background?: string
}
const StyledElementPanelWrapper = styled.div<StyledElementPanelWrapperType>`
  margin-right: 16px;
  padding: 20px;
  min-width: 130px;
  border-radius: 18px;
  background-color: ${(props) => props.background};
`

const StyledElementIconWrapper = styled.div``

const StyledElementTitle = styled.span`
  display: block;
  margin-top: 30px;
`

const StyledElementCountTasks = styled.span`
  display: block;
  margin-top: 5px;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 700;
`
