import React from 'react'

type PropsType = {}

const FilterArrowIcon: React.FC<PropsType> = (props) => {
  return (
    <svg
      width="6"
      height="3"
      viewBox="0 0 6 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 -2.62268e-07L0 3L6 3L3 -2.62268e-07Z"
        fill="#40434E"
        fillOpacity="0.56"
      />
    </svg>
  )
}

export default FilterArrowIcon
