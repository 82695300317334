import React from 'react'
import styled from 'styled-components'

type PropsType = {
  text: string
  children?: React.ReactNode
}

const SidebarItem: React.FC<PropsType> = (props) => {
  return (
    <StyledSidebarItem>
      <StyledItemIconWrapper>{props.children}</StyledItemIconWrapper>

      <StyledItemText>{props.text}</StyledItemText>
    </StyledSidebarItem>
  )
}

export default SidebarItem

const StyledSidebarItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 88px;
  border-radius: 12px;
  cursor: pointer;

  & path {
    color: rgba(255, 255, 255, 0.72);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.24);

    & path {
      fill: #ffffff;
    }

    & span {
      color: #ffffff;
    }
  }
`

const StyledItemIconWrapper = styled.div`
  margin-top: 5px;
`

const StyledItemText = styled.span`
  display: block;
  margin-top: 6px;
  max-width: 52px;

  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.72);
`
