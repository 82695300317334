import React from 'react'
import Ul from 'Components/common/list/Ul'
import EmployeesItem from './EmployeesItem'
import UserPhoto from 'Assets/img/user1.jpg'
import { ReactComponent as Status } from 'Assets/img/status.svg'
import styled from 'styled-components'

type PropsType = {}

const EmployeesList: React.FC<PropsType> = (props) => {
  return (
    <EmployeesListWrapper>
      <Ul margin="12px 0 0 0">
        {employees.map(({ id, fullName, group, userPhoto, Status }) => (
          <EmployeesItem
            key={id}
            fullName={fullName}
            group={group}
            userPhoto={userPhoto}
            Status={Status}
          />
        ))}
      </Ul>
    </EmployeesListWrapper>
  )
}

export default EmployeesList

const EmployeesListWrapper = styled.div`
  position: absolute;
  left: 36px;
  right: 36px;
  top: 265px;
  bottom: 0;
  margin-top: 12px;
  padding: 0;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`
const employees = [
  {
    id: 1,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 2,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 3,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 4,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 5,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 6,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 7,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 8,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 9,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 10,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
  {
    id: 11,
    fullName: 'Бобров А.М.',
    group: 'ABC-312',
    userPhoto: UserPhoto,
    Status: <Status />,
  },
]
