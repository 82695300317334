import React, { useCallback } from 'react'
import styled from 'styled-components'
import Logo from 'Components/main/sidebar/items/Logo'
import Ul from 'Components/common/list/Ul'
import Li from 'Components/common/list/Li'
import SidebarItem from 'Components/main/sidebar/items/SidebarItem'
import TeamIcon from 'Assets/icons/TeamIcon'
import TaskIcon from 'Assets/icons/TaskIcon'
import RefIcon from 'Assets/icons/RefIcon'
import PAIcon from 'Assets/icons/PAIcon'
import ExitIcon from 'Assets/icons/ExitIcon'
import FlexColumnCenter from 'Components/common/FlexContainers/FlexColumnCenter'

const sidebarUpItems = [
  { id: 1, text: 'Команда', Icon: TeamIcon },
  { id: 2, text: 'Новая задача', Icon: TaskIcon },
  { id: 3, text: 'Справка', Icon: RefIcon },
]

const sidebarDownItems = [
  { id: 1, text: 'Личный кабинет', Icon: PAIcon },
  { id: 2, text: 'Выход', Icon: ExitIcon },
]

type sidebarUpItemsType = typeof sidebarUpItems

type PropsType = {}

const Sidebar: React.FC<PropsType> = (props) => {
  const renderItems = (sidebarItems: sidebarUpItemsType): JSX.Element[] => {
    return sidebarItems.map((i) => (
      <Li key={i.id}>
        <SidebarItem text={i.text}>
          <i.Icon />
        </SidebarItem>
      </Li>
    ))
  }

  return (
    <StyledSideBarWrapper>
      <FlexColumnCenter padding="10px 16px 18px 16px" height="100%">
        <Logo />

        <FlexColumnCenter grow="1" margin="10px 0 0 0">
          <Ul marginLiBottom="15px">{renderItems(sidebarUpItems)}</Ul>

          <Ul marginLiBottom="15px" margin="auto 0 0 0">
            {renderItems(sidebarDownItems)}
          </Ul>
        </FlexColumnCenter>
      </FlexColumnCenter>
    </StyledSideBarWrapper>
  )
}

export default Sidebar

const StyledSideBarWrapper = styled.div`
  background: #36a1ea;
  height: 100%;
`
