import React from 'react'
import Flex, { FlexPropsType } from './Flex'

const FlexColumnCenter: React.FC<FlexPropsType> = (props) => {
  return (
    <Flex direction="column" justify="center" align="center" {...props}>
      {props.children}
    </Flex>
  )
}

export default FlexColumnCenter
