import React from 'react'
import styled, { css } from 'styled-components'
import FlexCenter from './FlexContainers/FlexCenter'
import FilterArrowIcon from 'Assets/icons/FIlterArrowIcon'

type PropsType = {
  text: string
} & StyledIconWrapperType &
  StyledButtonWrapperType

const FilterButton: React.FC<PropsType> = (props) => {
  return (
    <StyledButtonWrapper margin={props.margin}>
      <FlexCenter>
        <StyledButtonTitle>{props.text}</StyledButtonTitle>

        <StyledIconWrapper desc={props.desc}>
          <FilterArrowIcon />
        </StyledIconWrapper>
      </FlexCenter>
    </StyledButtonWrapper>
  )
}

export default FilterButton

type StyledButtonWrapperType = {
  margin?: string
}
const StyledButtonWrapper = styled.button<StyledButtonWrapperType>`
  display: block;
  margin: ${(props) => props.margin};
  cursor: pointer;
`

const StyledButtonTitle = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  opacity: 0.56;
`

type StyledIconWrapperType = {
  desc?: boolean
}
const StyledIconWrapper = styled.div<StyledIconWrapperType>`
  margin-left: 6px;
  font-size: 0;
  ${(props) =>
    props.desc &&
    css`
      transform: rotateZ(180deg);
    `}
`
