import React from 'react'
import styled from 'styled-components'
import FlexBetween from 'Components/common/FlexContainers/FlexBetween'
import Li from 'Components/common/list/Li'
import FlexCenter from 'Components/common/FlexContainers/FlexCenter'

type PropsType = {
  fullName: string
  group: string
  userPhoto: string
  Status: JSX.Element
}

const EmployeesItem: React.FC<PropsType> = (props) => {
  return (
    <Li>
      <StyledEmployeesItemWrapper>
        <FlexBetween padding="12px 0 12px 0">
          <FlexCenter>
            <StyledPhoto src={props.userPhoto} alt="User photo" />

            <StyledInfoWrapper>
              <StyledName>{props.fullName}</StyledName>
              <StyledGroup>{props.group}</StyledGroup>
            </StyledInfoWrapper>
          </FlexCenter>

          {props.Status}
        </FlexBetween>

        <StyledButtonLine />
      </StyledEmployeesItemWrapper>
    </Li>
  )
}

export default EmployeesItem

const StyledEmployeesItemWrapper = styled.div`
  position: relative;
  padding: 0 12px;
  border-radius: 12px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: #ffffff;
    & > div {
      border-color: transparent;
    }
  }
`

const StyledPhoto = styled.img`
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
`

const StyledInfoWrapper = styled.div`
  margin-left: 12px;
`

const StyledName = styled.span`
  display: block;
`

const StyledGroup = styled.span`
  display: block;
  opacity: 0.56;
`

const StyledButtonLine = styled.div`
  transform: translateY(1px);
  border-bottom: 1px solid rgba(64, 67, 78, 0.1);
`
