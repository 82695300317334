import React from 'react'
import { createGlobalStyle } from 'styled-components'
import Sidebar from 'Components/main/sidebar/Sidebar'
import EmployeesPanel from 'Components/main/employeesPanel/EmployeesPanel'
import TaskPanel from 'Components/main/taskPanel/TaskPanel'
import MessagesPanel from 'Components/main/messagePanel/MessagesPanel'
import { colors } from './Style/colors'
import Flex from 'Components/common/FlexContainers/Flex'

type GlobalType = {
  color: string
}
const Global = createGlobalStyle<GlobalType>`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;

  li {
    list-style-type: none;
  }

  button {
    border-width: 0;
    background-color: transparent;
  }
  
  body {
    color: ${(props) => props.color};
  }  
}
`

const App = () => {
  return (
    <>
      <Global color={colors.text.color} />

      <Flex justify="stretch" height="100vh">
        <Sidebar />
        <EmployeesPanel />
        <TaskPanel />
        <MessagesPanel />
      </Flex>
    </>
  )
}

export default App
