import React from 'react'
import styled from 'styled-components'
import TogglePanelItem from 'Components/common/togglePanel/TogglePanelItem'

type TogglePanelItemType = {
  id: number
  text: string
  isActive: boolean
  isSearch?: boolean
}

type PropsType = {
  togglePanelItems: Array<TogglePanelItemType>
} & StyledTogglePanelWrapperType &
  StyledTogglePanelListWrapperType

const TogglePanel: React.FC<PropsType> = (props) => {
  const items = props.togglePanelItems.map(({ id, text, isActive, isSearch }) => {
    return (
      <TogglePanelItem isSearch={isSearch} key={id} text={text} isActive={isActive} />
    )
  })

  return (
    <StyledTogglePanelWrapper margin={props.margin}>
      <StyledTogglePanelListWrapper spaceItemBetween={props.spaceItemBetween}>
        {items}
      </StyledTogglePanelListWrapper>
    </StyledTogglePanelWrapper>
  )
}

export default TogglePanel

type StyledTogglePanelWrapperType = {
  margin?: string
}
const StyledTogglePanelWrapper = styled.div<StyledTogglePanelWrapperType>`
  margin: ${(props) => props.margin};
  border-radius: 12px;
  padding: 4px;

  background-color: rgba(54, 161, 234, 0.07);
`

type StyledTogglePanelListWrapperType = {
  spaceItemBetween?: string
}
const StyledTogglePanelListWrapper = styled.ul<StyledTogglePanelListWrapperType>`
  display: flex;
  justify-content: space-between;

  & li:not(:last-child) {
    margin-right: ${(props) => props.spaceItemBetween};
  }
`
