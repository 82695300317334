import React from 'react'
import styled from 'styled-components'
import Li from 'Components/common/list/Li'
import { colors } from 'Style/colors'
import EllipsisIcon from 'Assets/icons/EllipsisIcon'
import Flex from 'Components/common/FlexContainers/Flex'
import Tag from 'Components/common/Tag'

type PropsType = {
  userPhoto: string
  fullName: string
  date: string
  group: string
  status: string
  text: string
}

const Message: React.FC<PropsType> = (props) => {
  let variant: 'negative' | 'positive' | 'basic' | '' = ''
  let statusText = ''
  switch (props.status) {
    case 'done':
      variant = 'positive'
      statusText = 'ГОТОВО'
      break
    case 'miss':
      variant = 'negative'
      statusText = 'СРЫВ СРОКОВ'
      break
    case 'in_work':
      variant = 'basic'
      statusText = 'В РАБОТЕ'
      break
  }

  return (
    <Li>
      <StyledMessageWrapper backgroundHover={colors.background.color}>
        <Flex padding="18px 18px 26px 18px" align="flex-start">
          <StyledPhoto src={props.userPhoto} alt="user-photo" />
          <StyledInfo>
            <Flex align="center" margin="2px 0 0 0">
              <StyledFullName>{props.fullName}</StyledFullName>
              <StyledDate>{props.date}</StyledDate>
            </Flex>
            <Flex>
              <StyledGroup>{props.group}</StyledGroup>
              <Tag variant={variant} margin="0 0 0 6px">
                {statusText}
              </Tag>
            </Flex>

            <StyledText>{props.text}</StyledText>

            <StyledPropertyMassage>
              <EllipsisIcon />
            </StyledPropertyMassage>
          </StyledInfo>
        </Flex>
        <StyledButtonLine />
      </StyledMessageWrapper>
    </Li>
  )
}

export default Message

type StyledMessageWrapperType = {
  backgroundHover?: string
}
const StyledMessageWrapper = styled.div<StyledMessageWrapperType>`
  position: relative;
  border-radius: 18px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: ${(props) => props.backgroundHover};
    & > div {
      border-color: transparent;
    }

    & strong {
      display: block;
    }
  }
`
const StyledPhoto = styled.img`
  display: block;
  border-radius: 50%;
`

const StyledInfo = styled.div`
  position: relative;
  margin-left: 18px;
  width: 344px;
`

const StyledFullName = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

const StyledDate = styled.span`
  display: block;
  margin-left: 15px;
  opacity: 0.56;
`

const StyledGroup = styled.span`
  display: block;
  opacity: 0.56;
`

const StyledText = styled.span`
  display: block;
  margin-top: 10px;
  letter-spacing: 0.02em;
`

const StyledPropertyMassage = styled.strong`
  position: absolute;
  top: 10px;
  right: 13px;
  display: none;
`

const StyledButtonLine = styled.div`
  margin: 0 18px;
  transform: translateY(1px);
  border-bottom: 1px solid rgba(64, 67, 78, 0.1);
`
