import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Li from 'Components/common/list/Li'
import FlexBetween from 'Components/common/FlexContainers/FlexBetween'
import FlexCenter from 'Components/common/FlexContainers/FlexCenter'
import { colors } from 'Style/colors'
import EllipsisIcon from 'Assets/icons/EllipsisIcon'
import Tag from 'Components/common/Tag'

type PropsType = {
  group: string
  indicator: string
  title: string
  department: string
  executor: string
  timing: string
}

const Task: React.FC<PropsType> = (props) => {
  type variantType = 'negative' | 'positive' | 'basic' | undefined
  const changeVariant = (indicator: string): variantType => {
    switch (indicator) {
      case 'HIG':
        return 'negative'
      case 'LOW':
        return 'positive'
      case 'NOR':
        return 'basic'
    }
  }

  const variant = useMemo(() => changeVariant(props.indicator), [props.indicator])

  return (
    <Li>
      <StyledTaskWrapper backgroundHover={colors.background.colorSecondary}>
        <FlexBetween padding="13px 10px 13px 10px">
          <FlexCenter>
            <StyledGroup>{props.group}</StyledGroup>
            <Tag variant={variant} type="fix" margin="0 0 0 18px">
              {props.indicator}
            </Tag>
            <StyledTitle>{props.title}</StyledTitle>
          </FlexCenter>

          <FlexCenter margin="0 0 0 10px">
            <StyledDepartment>{props.department}</StyledDepartment>
            <StyledExecutor>{props.executor}</StyledExecutor>
            <StyledTiming variant={variant}>{props.timing}</StyledTiming>
            <StyledPropertyTask>
              <EllipsisIcon />
            </StyledPropertyTask>
          </FlexCenter>
        </FlexBetween>

        <StyledButtonLine />
      </StyledTaskWrapper>
    </Li>
  )
}

export default Task

type StyledTaskWrapperType = {
  backgroundHover?: string
}
const StyledTaskWrapper = styled.div<StyledTaskWrapperType>`
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: ${(props) => props.backgroundHover};
    & > div {
      border-color: transparent;
    }

    & strong {
      display: block;
    }
  }
`
const StyledGroup = styled.span`
  display: block;
  font-size: 10px;
  font-weight: 700;
  opacity: 0.56;
`
const StyledTitle = styled.span`
  display: block;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
`
const StyledDepartment = styled.span`
  display: block;
  width: 50px;
  opacity: 0.56;
`
const StyledExecutor = styled.span`
  display: block;
  margin-left: 10px;
  width: 118px;
  opacity: 0.56;
`

type StyledTimingType = {
  variant?: string
}
const StyledTiming = styled.span<StyledTimingType>`
  display: block;
  margin-left: 10px;
  width: 124px;
  opacity: 0.56;

  ${(props) =>
    props.variant === 'negative' &&
    css`
      color: #ff5154;
    `}
`

const StyledPropertyTask = styled.strong`
  position: absolute;
  top: 12px;
  right: 16px;
  display: none;
`

const StyledButtonLine = styled.div`
  margin: 0 10px;
  transform: translateY(1px);
  border-bottom: 1px solid rgba(64, 67, 78, 0.1);
`
