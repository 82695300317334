import React from 'react'
import styled from 'styled-components'
import Ul from 'Components/common/list/Ul'
import UserPhoto from 'Assets/img/user1.jpg'
import Message from 'Components/main/messagePanel/items/Message'

type PropsType = {}

const MessagesList: React.FC<PropsType> = (props) => {
  return (
    <StyledTasks>
      <Ul margin="20px 0 0 0">
        {messages.map(({ id, userPhoto, fullName, date, group, status, text }) => (
          <Message
            key={id}
            userPhoto={userPhoto}
            fullName={fullName}
            date={date}
            group={group}
            status={status}
            text={text}
          />
        ))}
      </Ul>
    </StyledTasks>
  )
}

export default MessagesList

const StyledTasks = styled.div`
  flex-grow: 1;
  margin-top: 11px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`

const messages = [
  {
    id: 1,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'in_work',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 2,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'done',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 3,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'in_work',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 4,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'done',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 5,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'miss',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 6,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'miss',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 7,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'done',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 8,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'in_work',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 9,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'done',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
  {
    id: 10,
    userPhoto: UserPhoto,
    fullName: 'Бобров А.М.',
    date: 'Вчера 18:00',
    group: 'ABC-312',
    status: 'in_work',
    text: 'Люди уезжают от безысходности. Я понимаю, активность, успех,все дела. Мне из Окхаломщины…',
  },
]
