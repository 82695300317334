import React from 'react'
import LogoIcon from 'Assets/icons/LogoIcon'
import FlexCenter from 'Components/common/FlexContainers/FlexCenter'

type PropsType = {}

const Logo: React.FC<PropsType> = (props) => {
  return (
    <FlexCenter height="88px" width="88px">
      <LogoIcon />
    </FlexCenter>
  )
}

export default Logo
