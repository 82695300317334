import React from 'react'
import styled from 'styled-components'
import FilterButton from 'Components/common/FilterButton'
import FlexBetween from 'Components/common/FlexContainers/FlexBetween'
import FlexCenter from 'Components/common/FlexContainers/FlexCenter'

type PropsType = {}

const TasksSort: React.FC<PropsType> = (props) => {
  return (
    <StyledTasksSortWrapper>
      <StyledTasksSort>
        <FlexBetween>
          <FilterButton text="Список задач" desc />

          <FlexCenter margin="0 90px 0 0">
            <FilterButton text="Отдел" desc margin="0 15px 0 0" />
            <FilterButton text="Исполнитель" margin="0 32px 0 0" />
            <FilterButton text="Сроки" />
          </FlexCenter>
        </FlexBetween>
      </StyledTasksSort>
      <StyledTasksBottomSmoke />
    </StyledTasksSortWrapper>
  )
}

export default TasksSort

const StyledTasksSortWrapper = styled.div`
  margin-top: 19px;
`

const StyledTasksSort = styled.div`
  padding-bottom: 9px;
  border-bottom: 1px solid rgba(64, 67, 78, 0.1);
`

const StyledTasksBottomSmoke = styled.div`
  position: absolute;
  height: 65px;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(180deg, rgba(242, 247, 250, 0) 0%, #f2f7fa 100%);
  z-index: 1;
`
