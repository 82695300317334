import React from 'react'

type PropsType = {}

const CompletedIcon: React.FC<PropsType> = (props) => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666656 8C0.666656 3.58172 4.24838 0 8.66666 0H28.6667C33.0849 0 36.6667 3.58172 36.6667 8V28C36.6667 32.4183 33.0849 36 28.6667 36H8.66666C4.24838 36 0.666656 32.4183 0.666656 28V8Z"
        fill="#00CC8F"
        fillOpacity="0.16"
      />
      <path
        d="M21.3867 14.79L17.0967 19.09L15.4467 17.44C15.357 17.3353 15.2467 17.2503 15.1226 17.1903C14.9986 17.1303 14.8634 17.0965 14.7257 17.0912C14.588 17.0859 14.4507 17.1091 14.3223 17.1594C14.194 17.2096 14.0775 17.2859 13.98 17.3833C13.8826 17.4808 13.8063 17.5974 13.756 17.7257C13.7057 17.854 13.6825 17.9913 13.6879 18.1291C13.6932 18.2668 13.7269 18.4019 13.7869 18.526C13.847 18.65 13.932 18.7604 14.0367 18.85L16.3867 21.21C16.4801 21.3027 16.5909 21.376 16.7128 21.4258C16.8346 21.4755 16.9651 21.5008 17.0967 21.5C17.359 21.4989 17.6104 21.3947 17.7967 21.21L22.7967 16.21C22.8904 16.117 22.9648 16.0064 23.0156 15.8846C23.0663 15.7627 23.0925 15.632 23.0925 15.5C23.0925 15.368 23.0663 15.2373 23.0156 15.1154C22.9648 14.9936 22.8904 14.883 22.7967 14.79C22.6093 14.6037 22.3558 14.4992 22.0917 14.4992C21.8275 14.4992 21.574 14.6037 21.3867 14.79ZM18.6667 8C16.6888 8 14.7555 8.58649 13.111 9.6853C11.4665 10.7841 10.1847 12.3459 9.42787 14.1732C8.67099 16.0004 8.47296 18.0111 8.85881 19.9509C9.24466 21.8907 10.1971 23.6725 11.5956 25.0711C12.9941 26.4696 14.7759 27.422 16.7158 27.8079C18.6556 28.1937 20.6662 27.9957 22.4935 27.2388C24.3208 26.4819 25.8825 25.2002 26.9814 23.5557C28.0802 21.9112 28.6667 19.9778 28.6667 18C28.6667 16.6868 28.408 15.3864 27.9055 14.1732C27.4029 12.9599 26.6663 11.8575 25.7377 10.9289C24.8091 10.0003 23.7068 9.26375 22.4935 8.7612C21.2802 8.25866 19.9799 8 18.6667 8ZM18.6667 26C17.0844 26 15.5377 25.5308 14.2221 24.6518C12.9065 23.7727 11.8811 22.5233 11.2756 21.0615C10.6701 19.5997 10.5117 17.9911 10.8204 16.4393C11.1291 14.8874 11.891 13.462 13.0098 12.3431C14.1286 11.2243 15.5541 10.4624 17.1059 10.1537C18.6578 9.84504 20.2663 10.0035 21.7281 10.609C23.1899 11.2145 24.4394 12.2398 25.3184 13.5554C26.1975 14.871 26.6667 16.4177 26.6667 18C26.6667 20.1217 25.8238 22.1566 24.3235 23.6569C22.8232 25.1571 20.7884 26 18.6667 26Z"
        fill="#1DC376"
      />
    </svg>
  )
}

export default CompletedIcon
