import React from 'react'
import styled, { css } from 'styled-components'
import FlexCenter from './FlexContainers/FlexCenter'
import { colors, colorsType } from 'Style/colors'

type PropsType = {
  text?: string
  icon?: JSX.Element
} & CommonType

const Button: React.FC<PropsType> = (props) => {
  return (
    <StyledButtonWrapper
      variant={props.variant}
      size={props.size}
      margin={props.margin}
      padding={props.padding}
      colors={colors}
    >
      <FlexCenter>
        {props.text && (
          <StyledButtonTitle variant={props.variant} colors={colors}>
            {props.text}
          </StyledButtonTitle>
        )}

        {props?.icon && (
          <StyledIconWrapper variant={props.variant} colors={colors}>
            {props?.icon}
          </StyledIconWrapper>
        )}
      </FlexCenter>
    </StyledButtonWrapper>
  )
}

export default Button

type CommonType = {
  variant: 'base' | 'clear' | 'full'
  size: 'S' | 'L'
  margin?: string
  padding?: string
}

type StyledButtonWrapper = CommonType & StyledButtonCSSWrapperType

type StyledButtonCSSWrapperType = {
  colors?: colorsType
}

const StyledButtonWrapper = styled.button<StyledButtonWrapper>`
  display: block;
  cursor: pointer;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  ${(props) =>
    props.variant === 'base' &&
    css`
      background-color: ${props.colors?.buttons.color};
    `}
  ${(props) =>
    props.variant === 'full' &&
    css`
      background-color: ${props.colors?.buttons.colorSecondary};
    `}

  ${(props) =>
    props.size === 'L' &&
    css`
      padding: 10px 20px;
      min-height: 44px;
      min-width: 44px;
      border-radius: 8px;
    `}
  ${(props) =>
    props.size === 'S' &&
    css`
      min-height: 24px;
      min-width: 24px;
      border-radius: 6px;
      font-weight: 800;
    `}
`

type StyledButtonItemType = {
  variant: 'base' | 'clear' | 'full'
} & StyledButtonCSSWrapperType

const StyledButtonTitle = styled.span<StyledButtonItemType>`
  display: block;
  color: ${(props) => props.colors?.buttons.text};
  ${(props) =>
    props.variant === 'base' &&
    css`
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
    `}
  ${(props) =>
    props.variant === 'clear' &&
    css`
      font-weight: 800;
      color: ${props.colors?.buttons.color};
      }
    `}
`

const StyledIconWrapper = styled.div<StyledButtonItemType>`
  font-size: 0;
  ${(props) =>
    props.variant === 'full' &&
    css`
      color: ${props.colors?.buttons.color};
    `}
`
