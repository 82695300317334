import React from 'react'
import styled from 'styled-components'
import Ul from 'Components/common/list/Ul'
import Task from './Task'

type PropsType = {}

const TasksList: React.FC<PropsType> = (props) => {
  return (
    <StyledTasks>
      <Ul>
        {tasks.map(({ id, group, indicator, title, department, executor, timing }) => (
          <Task
            key={id}
            group={group}
            indicator={indicator}
            title={title}
            department={department}
            executor={executor}
            timing={timing}
          />
        ))}
      </Ul>
    </StyledTasks>
  )
}

export default TasksList

const StyledTasks = styled.div`
  flex-grow: 1;
  margin-top: 11px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`

const tasks = [
  {
    id: 1,
    group: 'ABC-312',
    indicator: 'HIG',
    title: 'Создание индивидуального стиля',
    department: 'Mob',
    executor: 'Смирнов А.М.',
    timing: 'Вчера 18:00',
  },
  {
    id: 2,
    group: 'ABC-311',
    indicator: 'HIG',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Иванов Б.Н.',
    timing: 'Вчера 18:00',
  },
  {
    id: 3,
    group: 'ABC-310',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 4,
    group: 'ABC-310',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 5,
    group: 'ABC-310',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 6,
    group: 'ABC-310',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 7,
    group: 'ABC-310',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 8,
    group: 'ABC-310',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 9,
    group: 'ABC-304',
    indicator: 'LOW',
    title: 'Создание графических макетов',
    department: 'Mob',
    executor: 'Морозов Л.Х.',
    timing: '4 дня ',
  },
  {
    id: 10,
    group: 'ABC-303',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 11,
    group: 'ABC-302',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 12,
    group: 'ABC-302',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 13,
    group: 'ABC-302',
    indicator: 'NOR',
    title: 'Проверка программы на соответствие требован..',
    department: 'Mob',
    executor: 'Кузнецов В.О.',
    timing: '2 дня ',
  },
  {
    id: 14,
    group: 'ABC-304',
    indicator: 'LOW',
    title: 'Создание графических макетов',
    department: 'Mob',
    executor: 'Морозов Л.Х.',
    timing: '4 дня ',
  },
  {
    id: 15,
    group: 'ABC-299',
    indicator: 'LOW',
    title: 'Создание графических макетов',
    department: 'Mob',
    executor: 'Морозов Л.Х.',
    timing: '4 дня ',
  },
]
