import React from 'react'
import styled from 'styled-components'
import Container from 'Components/common/Container'
import TogglePanel from 'Components/common/togglePanel/TogglePanel'
import SearchInput from 'Components/main/employeesPanel/items/Search'
import EmployeesSort from 'Components/main/employeesPanel/items/EmployeesSort'
import EmployeesList from 'Components/main/employeesPanel/items/list/EmployeesList'

type PropsType = {}

const EmployeesPanel: React.FC<PropsType> = (props) => {
  const togglePanelItems = [
    {
      id: 1,
      text: 'В работе',
      isActive: false,
    },
    {
      id: 2,
      text: 'Сотрудники',
      isActive: false,
    },
    {
      id: 3,
      text: '',
      isActive: true,
      isSearch: true,
    },
  ]

  return (
    <StyledEmployeesPanel>
      <Container height="100vh" minWidth="350px">
        <StyledTitleEmployeesPanel>Проект автоматизации</StyledTitleEmployeesPanel>

        <StyledDescriptionEmployeesPanel>
          Software project
        </StyledDescriptionEmployeesPanel>

        <TogglePanel
          spaceItemBetween="4px"
          margin="31px 0"
          togglePanelItems={togglePanelItems}
        />

        <SearchInput />

        <EmployeesSort />

        <EmployeesList />

        <StyledBottomSmoke />
      </Container>
    </StyledEmployeesPanel>
  )
}

export default EmployeesPanel

const StyledEmployeesPanel = styled.div`
  background-color: #f2f7fa;
`

const StyledTitleEmployeesPanel = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.01em;
`

const StyledDescriptionEmployeesPanel = styled.span`
  display: block;
  margin-top: 5px;

  color: rgba(64, 67, 78, 0.56);
`

export const StyledBottomSmoke = styled.div`
  position: absolute;
  height: 65px;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(180deg, rgba(242, 247, 250, 0) 0%, #f2f7fa 100%);
  z-index: 1;
`
