import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'Style/colors'

type PropsType = {
  children?: React.ReactNode
} & StyledTag

const Tag: React.FC<PropsType> = (props) => {
  return (
    <StyledTag
      colorPositive={colors.tags.positive}
      colorNegative={colors.tags.negative}
      colorBasic={colors.tags.basic}
      variant={props.variant}
      type={props.type}
      margin={props.margin}
    >
      {props.children}
    </StyledTag>
  )
}

export default Tag

type StyledTag = {
  type?: 'fix'
  variant?: 'negative' | 'positive' | 'basic' | ''
  margin?: string
}
type CSSType = {
  colorNegative: string
  colorPositive: string
  colorBasic: string
}
const StyledTag = styled.div<StyledTag & CSSType>`
  display: inline-flex;
  margin: ${(props) => props.margin};
  padding: 3px 6px 3px 6px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  ${(props) =>
    props.variant === 'negative' &&
    css`
      background-color: ${props.colorNegative};
    `}

  ${(props) =>
    props.variant === 'positive' &&
    css`
      background-color: ${props.colorPositive};
    `}

  ${(props) =>
    props.variant === 'basic' &&
    css`
      padding: 2px 5px 2px 5px;
      background-color: transparent;
      border: 1px solid ${props.colorBasic};
      color: ${props.colorBasic};
    `}

  ${(props) =>
    props.type === 'fix' &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 32px;
      height: 20px;
    `}
`
