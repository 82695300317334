import React from 'react'
import styled, { css } from 'styled-components'
import FlexCenter from 'Components/common/FlexContainers/FlexCenter'
import SearchIcon from 'Assets/icons/SearchIcon'

type PropsType = {
  text: string
  isActive: boolean
  isSearch?: boolean
}

const TogglePanelItem: React.FC<PropsType> = (props) => {
  return (
    <StyledTogglePanelItem isActive={props.isActive} isSearch={props.isSearch}>
      <FlexCenter>
        <StyledTogglePanelItemTitle text={props.text}>
          {props.text}
        </StyledTogglePanelItemTitle>

        {props.isSearch && <SearchIcon />}
      </FlexCenter>
    </StyledTogglePanelItem>
  )
}

export default TogglePanelItem

type StyledTogglePanelItemType = {
  isActive: boolean
  isSearch?: boolean
}

const StyledTogglePanelItem = styled.li<StyledTogglePanelItemType>`
  display: block;
  padding: 9px 16px;
  border-radius: 8px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #ffffff;
    `}

  ${(props) =>
    props.isSearch &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 18px;
      padding: 9px 12px;
    `}
`

type StyledTogglePanelItemTitleType = {
  text: string
}
const StyledTogglePanelItemTitle = styled.span<StyledTogglePanelItemTitleType>`
  display: block;
`
