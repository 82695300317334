import React from 'react'
import styled from 'styled-components'

type PropsType = {
  margin?: string
  marginLiBottom?: string
  padding?: string
  flexGrow?: string
  children?: React.ReactNode
}

const Ul: React.FC<PropsType> = (props) => {
  return <StyledUl {...props}>{props.children}</StyledUl>
}

export default Ul

const StyledUl = styled.ul<PropsType>`
  flex-grow: ${(props) => props.flexGrow};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  & li:not(:last-child) {
    margin-bottom: ${(props) => props.marginLiBottom};
  }
`
