import React from 'react'

type PropsType = {}

const RollUpArrowIcon: React.FC<PropsType> = (props) => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.500012 7.79999L6.20001 13.4C6.60001 13.8 7.20001 13.8 7.60001 13.4C8.00001 13 8.00001 12.4 7.60001 12L2.70001 6.99999L7.60001 1.99999C8.00001 1.59999 8.00001 0.999987 7.60001 0.599988C7.40001 0.399988 7.20001 0.299988 6.90001 0.299988C6.60001 0.299988 6.40001 0.399988 6.20001 0.599988L0.500012 6.19999C0.100012 6.69999 0.100012 7.29999 0.500012 7.79999C0.500012 7.69999 0.500012 7.69999 0.500012 7.79999Z" />
    </svg>
  )
}

export default RollUpArrowIcon
