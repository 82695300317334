import React from 'react'
import styled from 'styled-components'

export type FlexPropsType = {
  position?: string
  direction?: string
  justify?: string
  align?: string
  margin?: string
  padding?: string
  height?: string
  width?: string
  grow?: string
  borderRadius?: string
  borderBottom?: string
  backgroundColor?: string
  children?: React.ReactNode
}

const Flex: React.FC<FlexPropsType> = (props) => {
  return <StyledFlex {...props}>{props.children}</StyledFlex>
}

export default Flex

const StyledFlex = styled.div<FlexPropsType>`
  position: ${(props) => props.position};
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  flex-grow: ${(props) => props.grow};
  border-bottom: ${(props) => props.borderBottom};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
`
