import React from 'react'
import styled from 'styled-components'
import { colors } from 'Style/colors'
import TogglePanel from 'Components/common/togglePanel/TogglePanel'
import MessagesList from 'Components/main/messagePanel/items/MessagesList'
import FlexColumn from 'Components/common/FlexContainers/FlexColumn'

type PropsType = {}

const MessagesPanel: React.FC<PropsType> = (props) => {
  const togglePanelItems = [
    {
      id: 1,
      text: 'Уведомления',
      isActive: false,
    },
    {
      id: 2,
      text: 'Сообщения',
      isActive: true,
    },
  ]

  return (
    <StyledMessagesPanelWrapper background={colors.background.colorSecondary}>
      <FlexColumn
        padding="32px 36px 0 36px"
        align="flex-start"
        height="100%"
        position="relative"
      >
        <TogglePanel togglePanelItems={togglePanelItems} />
        <MessagesList />
        <StyledBottomSmoke />
      </FlexColumn>
    </StyledMessagesPanelWrapper>
  )
}

export default MessagesPanel

type StyledMessagesPanelWrapperType = {
  background?: string
}
const StyledMessagesPanelWrapper = styled.div<StyledMessagesPanelWrapperType>`
  background-color: ${(props) => props.background};
`

const StyledBottomSmoke = styled.div`
  position: absolute;
  height: 65px;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(180deg, rgba(242, 247, 250, 0) 0%, #f2f7fa 100%);
  z-index: 1;
`
