import React from 'react'
import styled from 'styled-components'
import FlexBetween from 'Components/common/FlexContainers/FlexBetween'
import FilterButton from 'Components/common/FilterButton'

type PropsType = {}

const EmployeesSort: React.FC<PropsType> = (props) => {
  return (
    <StyledFilterWrapper>
      <FlexBetween>
        <FilterButton text="Фио" desc />

        <FilterButton text="Норма" />
      </FlexBetween>
    </StyledFilterWrapper>
  )
}

export default EmployeesSort

const StyledFilterWrapper = styled.div`
  margin-top: 35px;
  padding-right: 5px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(64, 67, 78, 0.1);
`
